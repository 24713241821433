body {
    margin: 0;
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
        
:root {
    --nav-size: 60px;
    --speed: 500ms; 
    }
  
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
button{
    border-width:1px;
    border-style:solid;
    transition-duration: .2s;
}
button:hover{
    filter: brightness(.2s);
    cursor: pointer;
  }
  a{
    text-decoration: none;
  }

  a:hover{
    filter:brightnes(.2s)
  }

  