  .navbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
    
  }
  
  /* <ul> */
  .navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  
  /* <li> */
  .nav-item {
    width: calc(var(--nav-size) * 0.8);
    height: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:1.3em;
    font-weight:bolder;

  }
  
  /* Icon Button */
  .icon-button {
    --button-size: calc(var(--nav-size) * 0.8);
    width: var(--button-size);
    height: var(--button-size);
    /*
    background-color: #484a4d;
   */
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;


  }
  
  .headerLinkText{
    font-size:1.3em;
  }

  .headerLinkText:hover{
    filter: brightness(1.5);
  }
  
  .icon-button:hover {
    filter: brightness(1.5);
  }

  button:hover{
    filter: brightness(1.5);
  }
  
  .icon-button svg { 

    width: 20px;
    height: 20px;
  }

  .dropdown {
    position: absolute;
    top: 50px;
    min-width:200px;
    transform: translateX(-25%);

    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);

    overflow: hidden;
    transition: height var(--speed) ease;
  }
  
  .menu {
    width: 100%;
    border-style:solid;
    border-width:1px;
    padding-top:15px;
    padding-bottom:15px;



    border-radius:8px;
    
  }
  
  .menu-item {

    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);


  }
  
  .menu-item .icon-button:hover {
    filter: none;
  }

  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }
  
  .navbarOuterContainer{
    display:flex;
    flex:1;
    margin:auto;
    width:100%;
    flex-direction:row;
  }

  .navbarInnerContainer{
    display:flex;
    flex:1;
    margin:auto;
    max-width:1000px;
    flex-direction:row;

  }

  .dropdownRow
  {
    display:flex;
    flex-direction:row; 
  }

  .dropdownButton{
    /*
    background-color: rgb(33,32,30);
    */
    display:flex;
    flex:1;
    border-radius: 8px;
    border: 1px solid transparent;
    margin:3px;
    margin-left:10px;
    margin-right:10px;
    font-size:.8em;
    font-weight:bold;
    padding-top:3px;
    padding-bottom:3px;
    text-align:'center';
    justify-content: center;
    font-family:Arial, Helvetica, sans-serif;
  }

  .navbarHome{
    display:flex;
    flex:1;
    flex-direction:row;
    margin:auto;
    border-radius: 0px;
    border: 0px solid transparent;
  }
  .navbarLibrary{
    display:flex;
    flex:1;
    margin:auto;
    border-radius: 0px;
    border: 0px solid transparent;
  }

  .logoContainer{
    align-content:right;
    justify-content: right;
    display:flex;

    flex-direction:row;
    margin:auto;

  }
  .homeLinkContainer{
    display:flex;
    flex:2;
    flex-direction:row;
    margin:auto;

  }

